import React from 'react';
import { FaInstagram } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import { FaLinkedin } from "react-icons/fa";



const Footer = () => {
    return (
        <footer className="py-8 flex flex-col justify-center items-center bg-[#e2e2e2] px-5">
            <div className="max-w-full mx-auto  grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 text-[#23272b]">
                {/* Our Services Section */}
                   {/* About Us Section */}
                   <div className="col-span-1 md:col-span-1">
                    <h5 className="font-bold text-lg mb-3">Suburban Academy</h5>
                    <ul className='text-sm'>
                        <li><a href="https://signup.suburbanacademy.ng/">Register</a>
                        </li>
                        <li><a href="/about">About Us</a></li>
                        <li><a href="/contact">Contact Us</a></li>
                    </ul>
                </div>
              

                {/* Contact Information */}
                <div className="col-span-1 md:col-span-1">
                    <h5 className="font-bold text-lg mb-3">Contact Us</h5>
                    <ul className="space-y-1 text-sm">
                        <li>15 Bangui Street, off Ademola Adetokunbo Street,</li>
                        <li>Abuja, Nigeria</li>
                        <li>070 8000 0044 or 070 6000 0022</li>
                        <li>hello@suburbanacademy.ng</li>
                    </ul>
                </div>
            </div>

            {/* Copyright Section */}
            <div className="text-center mt-8 font-medium text-sm text-[#d50036]">
                <p className='mb-3'>Copyright 2024 Suburban Academy. All Rights Reserved</p>
                <a href="https://www.instagram.com/suburbanacademy/" target="_blank" rel="noopener noreferrer">
      <FaInstagram className="inline mr-3 text-5xl" />
    </a><a href="https://www.linkedin.com/showcase/the-suburban-academy/?trk=affiliated-pages" target="_blank" rel="noopener noreferrer"><FaLinkedin className='inline mr-3 text-5xl'/></a><a href="https://twitter.com/SuburbanAcademy" target="_blank" rel="noopener noreferrer"><RiTwitterXFill className='inline mr-3 text-5xl'/></a>
            </div>
            
        </footer>
    );
};

export default Footer;