import React from 'react';
import { Button } from 'primereact/button';
import { IoIosCheckmarkCircle } from "react-icons/io";
import { TiTick } from "react-icons/ti";

const AboutPage: React.FC = () => {
  return (
    <div>

      <div className="fadein animation-duration-1000 grid grid-nogutter text-800 bg-[#E2E2E2] h-50">
        <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center bg-[#e2e2e2] ">
          <section className=''>
            <span className="block text-3xl font-thin mb-2 tracking-wide font-bold">Supporting Educators and Institutions.
            </span>
            <p className="mt-0 mb-4 text-700 line-height-3 tracking-5">We engage with educational institutions and educators across Nigeria through various activities to ensure they maximize the benefits of The Suburban Academy:
            </p>
            <ul>
              <li><b>Direct Engagement: </b> Regular meetings with members to provide support, receive feedback, and introduce new resources.
              </li>
              <li><b>Events: </b> Quarterly online and hybrid events to help members learn about our offerings and hear from industry experts.
              </li>
              <li><b>Feedback and Improvement: </b> Roundtable discussions with educators to continually enhance our curriculum and resources.
              </li>
            </ul>
            <span className="block text-3xl font-thin mb-2 tracking-wide mt-3 font-bold">Celebrating Success Stories.
            </span>
            <p className="mt-0 mb-4 text-700 line-height-3 tracking-5">We highlight the achievements of our educators and students through success stories, showcasing how The Suburban Academy’s resources have made a difference in their educational journeys and career paths.
            </p>

          </section>
        </div>
        <div className="col-12 md:col-6 overflow-hidden bg-[#e2e2e2]">
          <img src="about-1.jpg" alt="hero-1" className="md:ml-auto block md:h-full object-contain animate-pulse" style={{ clipPath: 'polygon(8% 0, 100% 0%, 100% 100%, 0 100%)' }} />
        </div>
      </div>
      <div className="fadein animation-duration-1000 grid grid-nogutter text-800 bg-[#E2E2E2] h-50">
        <div className="col-12 md:col-6 overflow-hidden bg-[#e2e2e2]">
          <img src="about-2.jpg" alt="hero-1" className="md:ml-auto block md:m-h-full object-contain animate-pulse" style={{ clipPath: 'polygon(8% 0, 100% 0%, 100% 100%, 0 100%)' }} />
        </div>
        <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center bg-[#e2e2e2] ">
          <section className=''>
            <span className="block text-3xl font-thin mb-2 tracking-wide font-bold">Building Foundations in Technology
            </span>
            <p className='ml-1 mb-2 mt-2'>The Suburban Academy is proud to engage with educational institutions across Nigeria, enabling thousands of faculty members to prepare students for successful careers in technology.
            Our program aligns with international education standards, ensuring that our resources are both high-quality and globally recognized.</p>
            <span className="block text-3xl font-thin mb-2 tracking-wide font-bold">Join The Suburban Academy</span>
            <p className='ml-1 mb-2 mt-2'>We invite educational institutions and educators to join The Suburban Academy and take advantage of our free resources to enhance their teaching and support their students’ career aspirations.
            Together, we can build a brighter future for Nigeria by fostering technological innovation and expertise.</p>
          </section>
        </div>

      </div>
     
    </div>
  );
};

export default AboutPage;
