/* eslint-disable jsx-a11y/iframe-has-title */
import { Button } from 'primereact/button';
import React, { useState } from 'react';

import { TabView, TabPanel } from 'primereact/tabview';
import { Tag } from 'primereact/tag';

import { FaUbuntu } from "react-icons/fa";
import { GiArmorUpgrade } from "react-icons/gi";
import { IoLogoGameControllerA } from "react-icons/io";
import { IoIosCloudDone } from "react-icons/io";
import { TbNetwork } from "react-icons/tb";
import { ImLab } from "react-icons/im";
import { IoLogoJavascript } from "react-icons/io";
import { FaHtml5 } from "react-icons/fa";
import { FaAppStoreIos } from "react-icons/fa";
import { SiAdobephotoshop } from "react-icons/si";



import { FaPlay } from 'react-icons/fa'; //
const HomePage: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div>
      <div className="fadein animation-duration-1000 grid grid-nogutter text-800 bg-[#E2E2E2] h-50">
        <div className="col-12 md:col-4 p-6 text-center md:text-left flex align-items-center bg-[#e2e2e2]">
          <section className=''>
            <span className="block text-7xl font-thin mb-1 oct">Thrive In The 4th Industrial Revolution.            </span>
            <p className="mt-0 mb-4 text-700 line-height-3"> Carefully tailored courses and practical learning experiences to ensure that participants acquire global best practices and competencies from their home base.</p>

            <a href="https://signup.suburbanacademy.ng/">
              <Button
                label="Register Now!"
                type="button"
                className="p-button-outlined p-2 bg-[#d50036] text-white border border-[#d50036] px-5 rounded-full animate-pulse"
              />
            </a>          </section>
        </div>
        <div className="col-12 md:col-8 overflow-hidden bg-[#e2e2e2]">
          <img src="hero-alt.jpg" alt="hero-1" className="md:ml-auto block md:m-h-full object-cover" style={{ clipPath: 'polygon(8% 0, 100% 0%, 100% 100%, 0 100%)' }} />
        </div>
      </div>
      <div className="flex flex-wrap justify-between px-5 py-5 bg-red bg-gradient-custom object-contain" style={{ backgroundImage: "url('banner-bg.jpg')" }}>
        <div className="w-1/2 lg:w-1/4 p-2 bg-[#23272b]">
          <h2 className="text-lg mb-2 text-white font-thin">1. Registration</h2>
          <p className="text-sm text-white">Register by filling out the form with your details.</p>
        </div>
        <div className="w-1/2 lg:w-1/4 p-2 bg-[#d50036]">
          <h2 className="text-lg mb-2 text-white font-thin">2. Course Materials</h2>
          <p className="text-sm text-white">Access course materials from the online portal.</p>
        </div>
        <div className="w-1/2 lg:w-1/4 p-2 bg-[#23272b]">
          <h2 className="text-lg mb-2 text-white font-thin">3. Assessments</h2>
          <p className="text-sm text-white">Complete assessments at the end of each module.</p>
        </div>
        <div className="w-1/2 lg:w-1/4 p-2 bg-[#d50036]">
          <h2 className="text-lg mb-2 text-white font-thin">4. Certification</h2>
          <p className="text-sm text-white">Receive a certification upon course completion </p>
        </div>
      </div>

      <div className="grid grid-nogutter text-800 bg-[#EEEEEE] h-50 pl-0 sm:pl-[10rem] mt-[6rem] py-10">
        <div className="col-12 md:col-6 text-center md:text-left flex align-items-center bg-[#EEEEEE] pl-0 sm:pl-4 ">
          <section className='w-100'>

            <div className="card p-5 ml-0 sm:ml-3">
              <span className="block text-4xl font-semibold text-[#d50036] tracking-wide w-[100%] ml-0 sm:w-[70%] ml-1">Cloud Technology Course
              </span>

              <p className='pl-2 tracking-wide font-normal'>Our curriculum focuses on key areas of technology, providing an authentic learning experience for students.</p>

              <p className="mt-0 mb-0 text-700 line-height-3 ml-2 mt-3 pb-5">
                <ul className='w-[100%] m-auto sm:w-[80%] mx-0'>
                  <li className='border-bottom-custom-2 w-100 py-1'><IoIosCloudDone className='inline mr-2 text-[#d50036] text-4xl' /> <b>Cloud Infrastructure Foundations:</b> Basics of cloud
                    architecture, services, and deployment models.</li>
                  <li className='border-bottom-custom-2 w-100 py-1'> <TbNetwork className='inline mr-2 text-[#d50036] text-4xl' /> <b>Advanced Cloud Solutions:</b> Deep dive into cloud
                    security, data management, and cloud-native
                    applications.</li>
                  <li className='border-bottom-custom-2 w-100 py-1'><ImLab className='inline mr-2 text-[#d50036] text-4xl' /> <b>Hands-On Labs: </b> Access to Suburban Cloud’s sandbox
                    environment for real-world cloud practice.
                  </li>
                </ul>
              </p>
              <a href="https://signup.suburbanacademy.ng/">
                <Button
                  label="Enroll Now"
                  type="button"
                  className="p-button-outlined p-2 bg-[#d50036] text-white border border-[#d50036] px-5 rounded-full animate-pulse mb-4"
                />
              </a>
            </div>

          </section>
        </div>
        <div className="col-12 md:col-6 overflow-hidden bg-[#e2e2e2] nt-[5rem]">
          <img src="software.jpg" alt="hero-1" className="md:ml-auto block md:m-h-full -mt-5 object-cover" style={{ clipPath: 'polygon(0% 0, 100% 0%, 100% 100%, 0 100%)' }} />
        </div>
      </div>
      <div className="grid grid-nogutter text-800 bg-[#EEEEEE] h-50 pl-0 sm:pl-[10rem] mt-[6rem] py-10">
        <div className="col-12 md:col-6 text-center md:text-left flex align-items-center bg-[#EEEEEE] pl-0 sm:pl-4 ">
          <section className='w-100'>

            <div className="card p-5 ml-0 sm:ml-3">
              <span className="block text-4xl font-semibold text-[#d50036] tracking-wide w-[100%] ml-0 sm:w-[70%] ml-1">Software Development Course
              </span>

              <p className='pl-2 tracking-wide font-normal'>Our curriculum focuses on key areas of technology, providing an authentic learning experience for students.</p>

              <p className="mt-0 mb-0 text-700 line-height-3 ml-2 mt-3 pb-5">
                <ul className='w-[100%] m-auto sm:w-[80%] mx-0'>
                  <li className='border-bottom-custom-2 w-100 py-1'><IoLogoJavascript className='inline mr-2 text-[#d50036] text-4xl' /> <b>Programming Languages:</b> Courses on Python, Java, and JavaScript.</li>
                  <li className='border-bottom-custom-2 w-100 py-1'> <FaHtml5 className='inline mr-2 text-[#d50036] text-4xl' /> <b>Web Development:</b> Training on HTML, CSS, and modern JavaScript frameworks.
                   </li>
                  <li className='border-bottom-custom-2 w-100 py-1'><FaAppStoreIos className='inline mr-2 text-[#d50036] text-4xl' /> <b>Mobile App Development: </b> Building applications for Android and iOS platforms.
                    environment for real-world cloud practice.
                  </li>
                  <li className='border-bottom-custom-2 w-100 py-1'><SiAdobephotoshop className='inline mr-2 text-[#d50036] text-4xl' /> <b>Software Engineering Principles: </b> Best practices in software design, testing, and deployment.
                  </li>
                </ul>
              </p>
              <a href="https://signup.suburbanacademy.ng/">
                <Button
                  label="Enroll Now"
                  type="button"
                  className="p-button-outlined p-2 bg-[#d50036] text-white border border-[#d50036] px-5 rounded-full animate-pulse mb-4"
                />
              </a>
            </div>

          </section>
        </div>
        <div className="col-12 md:col-6 overflow-hidden bg-[#e2e2e2] nt-[5rem]">
          <img src="software-3.jpg" alt="hero-1" className="md:ml-auto block md:m-h-full -mt-5 object-cover" style={{ clipPath: 'polygon(0% 0, 100% 0%, 100% 100%, 0 100%)' }} />
        </div>
      </div>

      <div className="fadein animation-duration-1000 grid grid-nogutter text-800 bg-cover bg-center h-[30rem] py-10" style={{ backgroundImage: "url('vm-hero1.jpg')" }}>
        <div className="flex items-center justify-center h-full w-full">

          <a href="https://www.youtube.com/watch?v=tVrLjLXX-Sc" className="relative rounded-full bg-cover bg-center h-24 w-24" style={{ backgroundImage: "url('dbaas-hero.jpg')" }}>
            <div className="absolute inset-0 flex items-center justify-center bg-[#d50036] bg-opacity-100 rounded-full">
              <FaPlay className="text-white text-4xl ml-2 animate-pulse" fill='#e2e2e2' />

            </div>
          </a>
        </div>
      </div>



    </div>

  );
};

export default HomePage;
