import React from 'react';
import { TiTick } from "react-icons/ti";

const HowItWorksPage: React.FC = () => {
  return (
    <div>

      <div className="fadein animation-duration-1000 grid grid-nogutter text-800 bg-[#E2E2E2] h-50">
      <div className="col-12 md:col-6 order-2 md:order-1 p-6 text-center md:text-left flex align-items-center bg-[#e2e2e2]">
    <section className=''>
      <span className="block text-5xl font-thin mb-2">1. Registration, Confirmation & Welcome</span>
      <p className="mt-0 mb-0 text-700 line-height-3 tracking-5">Begin your journey by registering for the course. Once registered, you’ll receive a confirmation email along with a warm welcome message from our team.</p>
    </section>
  </div>
  <div className="col-12 md:col-6 order-1 md:order-2 overflow-hidden bg-[#e2e2e2]">
    <img src="/academy-hiw/1.png" alt="hero-1" className="md:ml-auto block md:h-full animate-pulse" />
  </div>
</div>

<div className="fadein animation-duration-1000 grid grid-nogutter text-800 bg-[#E2E2E2] h-50">
  <div className="col-12 md:col-6 order-1 md:order-2 overflow-hidden bg-[#e2e2e2]">
    <img src="/academy-hiw/2.png" alt="hero-1" className="md:ml-auto block md:h-full object-contain animate-pulse" />
  </div>
  <div className="col-12 md:col-6 order-2 md:order-1 p-6 text-center md:text-left flex align-items-center bg-[#e2e2e2]">
    <section className=''>
      <span className="block text-5xl font-thin mb-1">2.  Access to Learning Platform</span>
      <p className='ml-1 mb-2 mt-2'>Gain immediate access to our state-of-the-art learning platform, where you can explore a wealth of resources and tools designed to enhance your educational experience.</p>

    </section>
  </div>
      </div>
      <div className="fadein animation-duration-1000 grid grid-nogutter text-800 bg-[#E2E2E2] h-50">
  <div className="col-12 md:col-6 order-1 md:order-2 overflow-hidden bg-[#e2e2e2]">
    <img src="/academy-hiw/3.png" alt="hero-1" className="md:ml-auto block md:h-full object-contain animate-pulse" />
  </div>
  <div className="col-12 md:col-6 order-2 md:order-1 p-6 text-center md:text-left flex align-items-center bg-[#e2e2e2]">
    <section className=''>
      <span className="block text-5xl font-thin mb-1">3. Course Material Availability</span>
      <p className='ml-1 mb-2 mt-2'>All necessary course materials will be readily available for download or online access, ensuring you have everything you need to succeed.</p>
      
    </section>
  </div>

      </div>
      <div className="fadein animation-duration-1000 grid grid-nogutter text-800 bg-[#E2E2E2] h-50">
  <div className="col-12 md:col-6 order-1 md:order-2 overflow-hidden bg-[#e2e2e2]">
    <img src="/academy-hiw/4.png" alt="hero-1" className="md:ml-auto block md:h-full object-contain animate-pulse"  />
  </div>
  <div className="col-12 md:col-6 order-2 md:order-1 p-6 text-center md:text-left flex align-items-center bg-[#e2e2e2]">
    <section className=''>
      <span className="block text-5xl font-thin mb-1">4. Engagement and Participation</span>
      <p className='ml-1 mb-2 mt-2'>Engage actively in the learning process through interactive sessions, discussions, and group activities that foster collaboration and deeper understanding.</p>
    
    </section>
  </div>

      </div>
      <div className="fadein animation-duration-1000 grid grid-nogutter text-800 bg-[#E2E2E2] h-50">
  <div className="col-12 md:col-6 order-1 md:order-2 overflow-hidden bg-[#e2e2e2]">
    <img src="/academy-hiw/5.png" alt="hero-1" className="md:ml-auto block md:h-full object-contain animate-pulse" style={{ clipPath: 'polygon(8% 0, 100% 0%, 100% 100%, 0 100%)' }} />
  </div>
  <div className="col-12 md:col-6 order-2 md:order-1 p-6 text-center md:text-left flex align-items-center bg-[#e2e2e2]">
    <section className=''>
      <span className="block text-5xl font-thin mb-1">5. Assessments and Feedback</span>
      <p className='ml-1 mb-2 mt-2'>Participate in regular assessments to gauge your understanding and progress. Receive constructive feedback to help you improve and excel in your studies.</p>
     
    </section>
  </div>

      </div>
      <div className="fadein animation-duration-1000 grid grid-nogutter text-800 bg-[#E2E2E2] h-50">
  <div className="col-12 md:col-6 order-1 md:order-2 overflow-hidden bg-[#e2e2e2]">
    <img src="hero6.jpg" alt="hero-1" className="md:ml-auto block md:h-full object-contain animate-pulse" style={{ clipPath: 'polygon(8% 0, 100% 0%, 100% 100%, 0 100%)' }} />
  </div>
  <div className="col-12 md:col-6 order-2 md:order-1 p-6 text-center md:text-left flex align-items-center bg-[#e2e2e2]">
    <section className=''>
      <span className="block text-5xl font-thin mb-1">6. Progress Tracking</span>
      <p className='ml-1 mb-2 mt-2'>Monitor your learning journey with our comprehensive progress tracking system, allowing you to stay on top of your goals and milestones..</p>
     
    </section>
  </div>

      </div>
      <div className="fadein animation-duration-1000 grid grid-nogutter text-800 bg-[#E2E2E2] h-50">
  <div className="col-12 md:col-6 order-1 md:order-2 overflow-hidden bg-[#e2e2e2]">
    <img src="hero6.jpg" alt="hero-1" className="md:ml-auto block md:h-full object-contain animate-pulse" style={{ clipPath: 'polygon(8% 0, 100% 0%, 100% 100%, 0 100%)' }} />
  </div>
  <div className="col-12 md:col-6 order-2 md:order-1 p-6 text-center md:text-left flex align-items-center bg-[#e2e2e2]">
    <section className=''>
      <span className="block text-5xl font-thin mb-1">7. Final Assessment</span>
      <p className='ml-1 mb-2 mt-2'>Demonstrate your knowledge and skills through a final assessment, which will evaluate your overall performance and understanding of the course content.</p>
     
    </section>
  </div>

      </div>
      <div className="fadein animation-duration-1000 grid grid-nogutter text-800 bg-[#E2E2E2] h-50">
  <div className="col-12 md:col-6 order-1 md:order-2 overflow-hidden bg-[#e2e2e2]">
    <img src="hero6.jpg" alt="hero-1" className="md:ml-auto block md:h-full object-contain animate-pulse" style={{ clipPath: 'polygon(8% 0, 100% 0%, 100% 100%, 0 100%)' }} />
  </div>
  <div className="col-12 md:col-6 order-2 md:order-1 p-6 text-center md:text-left flex align-items-center bg-[#e2e2e2]">
    <section className=''>
      <span className="block text-5xl font-thin mb-1">8. Certification</span>
      <p className='ml-1 mb-2 mt-2'>Upon successfully completing the course and passing the final assessment, you will be awarded a certification that recognizes your achievement and expertise.</p>
     
    </section>
  </div>

      </div>
      <div className="fadein animation-duration-1000 grid grid-nogutter text-800 bg-[#E2E2E2] h-50">
  <div className="col-12 md:col-6 order-1 md:order-2 overflow-hidden bg-[#e2e2e2]">
    <img src="hero6.jpg" alt="hero-1" className="md:ml-auto block md:h-full object-contain animate-pulse" style={{ clipPath: 'polygon(8% 0, 100% 0%, 100% 100%, 0 100%)' }} />
  </div>
  <div className="col-12 md:col-6 order-2 md:order-1 p-6 text-center md:text-left flex align-items-center bg-[#e2e2e2]">
    <section className=''>
      <span className="block text-5xl font-thin mb-1">9.Empowered with Knowledge </span>
      <p className='ml-1 mb-2 mt-2'>Graduate from the course empowered with new knowledge and skills, ready to apply what you’ve learned in your personal or professional endeavors.</p>
     
    </section>
  </div>

      </div>
     
    </div>
  );
};

export default HowItWorksPage;
