import React, { useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import emailjs from 'emailjs-com';

const GeneralInquiryForm: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await emailjs.send(
        'service_v8503qk',     // Replace with your EmailJS service ID
        'template_eg3kx1p',    // Replace with your EmailJS template ID
        formData,
        'tbjlF0LbCyJTf2vxH'         // Replace with your EmailJS user ID
      );
      toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Message sent successfully!', life: 3000 });
      setFormData({ name: '', email: '', subject: '', message: '' });
    } catch (error) {
      toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to send message. Please try again later.', life: 3000 });
    }
    setLoading(false);
  };

  return (
    <div className="mb-4 w-full">
      <Toast ref={toast} />
      <form className="bg-white p-6 rounded border-custom mt-5 pl-4" onSubmit={handleSubmit}>
        <span className="block text-4xl font-thin mb-2">Contact Us</span>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-normal mb-2" htmlFor="generalName">Name</label>
          <input
            type="text"
            id="generalName"
            name="name"
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Your name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-normal mb-2" htmlFor="generalEmail">Email</label>
          <input
            type="email"
            id="generalEmail"
            name="email"
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Your email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-normal mb-2" htmlFor="generalSubject">Subject</label>
          <input
            type="text"
            id="generalSubject"
            name="subject"
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Subject"
            value={formData.subject}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-normal mb-2" htmlFor="generalMessage">Message</label>
          <textarea
            id="generalMessage"
            name="message"
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Your message"
            value={formData.message}
            onChange={handleChange}
          ></textarea>
        </div>
        <Button label="Submit" className="p-button-primary border-button p-2 w-full" type="submit" disabled={loading} />
        {loading && <div className="flex justify-center mt-3"><ProgressSpinner /></div>}
      </form>
    </div>
  );
};

export default GeneralInquiryForm;