import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useNavigate, useLocation } from 'react-router-dom';
import { Bars3Icon } from '@heroicons/react/24/outline';

const Navbar: React.FC = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location]);

  const start = (
    <div className="flex items-center h-full justify-center">
      <div className="text-2xl font-bold h-full flex items-center   text-accent cursor-pointer" onClick={() => navigate('/')}>
        <img src="/main-logo-2.png" alt="logo" className="w-5  sm:w-[12%]" />
      </div>
    </div>
  );

  const end = (
    <Button label="Login" className="p-button-primary px-4 py-1 rounded-full bg-[#d50036] text-white text-sm" onClick={() => window.location.href='https://login.suburbanacademy.ng/'} />
  );

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="fadein animation-duration-1000 bg-secondary">
      <div className="flex justify-between items-center  px-4 py-3">
        {start}
        <div className="hidden md:flex space-x-6 text-accent text-sm w-[35%]">
          <div className="cursor-pointer text-sm">
            <a href="https://signup.suburbanacademy.ng">Register</a>
          </div>
          <div className="cursor-pointer text-sm" onClick={() => navigate('/about')}>About Us</div>
          <div className="cursor-pointer text-sm" onClick={() => navigate('/contact')}>Contact Us</div>
        </div>
        <div className="flex items-center space-x-6">
          <div className="hidden md:block">{end}</div>
          <div className="md:hidden flex flex-row justify-end">
            <Bars3Icon className="h-10 text-accent cursor-pointer float-right" onClick={toggleMobileMenu} />
          </div>
        </div>
      </div>
      {isMobileMenuOpen && (
        <div className="md:hidden p-4">
          <ul className="space-y-4">
            <li className="cursor-pointer"><a href="https://signup.suburbanacademy.ng">Register</a></li>
            <li className="cursor-pointer" onClick={() => navigate('/about')}>About Us</li>
            <li className="cursor-pointer" onClick={() => navigate('/contact')}>Contact Us</li>
          </ul>
          <div className="mt-4">{end}</div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;